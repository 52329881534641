import React, { useEffect, useState } from "react";
import { RxVideo } from "react-icons/rx";
import { MdOutlineLocationOn } from "react-icons/md";
import { CiBoxes } from "react-icons/ci";
import { MdPriceCheck } from "react-icons/md";
// import { CiShop } from "react-icons/ci";

import Address from "./address";
import Training from "./training";
import MissModal from "./missModal";
import LinkModal from "./link";
import RaitingModal from "./raiting";
import PriceModal from "./priceModal";
import ForbiddenModal from "./forbidden";

const HomeModals = () => {
  const [state, setState] = useState({
    address_modal: false,
    modal_training: false,
    missModal: false,
    linkModal: false,
    modal_raiting: false,
    modal_price: false,
    for_modal: false,
  });
  useEffect(() => {
    if (
      state?.linkModal ||
      state?.address_modal ||
      state?.modal_training ||
      state?.missModal ||
      state?.modal_raiting ||
      state?.modal_price ||
      state?.for_modal
    ) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [
    state?.address_modal,
    state?.linkModal,
    state?.missModal,
    state?.modal_training,
    state?.modal_raiting,
    state?.modal_price,
    state?.for_modal,
  ]);
  return (
    <React.Fragment>
      <div className="flex gap-x-[1.4rem] gap-y-4 px-4 py-8 flex-wrap justify-around md:justify-start">
        {/* <div
          className="flex items-center flex-col text-[#F55B00] p-2 bg-white rounded-md cursor-pointer w-[150px] h-[150px] shadow-lg relative"
          onClick={() => {
            window.open("https://shop.erdenetcargo.mn", "_blank");
          }}
        >
          <div>
            <div className="text-base text-center font-medium">
              Онлайн дэлгүүр
            </div>
            <div className="text-[10px] text-center">
              Хятадаас бараа захиалах хэзээч ийм амар байсангүй.
            </div>
          </div>
          <CiShop className="w-full h-full" />
          <span className="blink bg-green-500 text-white text-xs font-medium me-2 px-2.5 py-0.5 rounded-full absolute bottom-2 right-1/2">
            Шинэ
          </span>
        </div> */}
        <div
          className="flex items-center flex-col text-[#F55B00] p-2 bg-white rounded-md cursor-pointer w-[150px] h-[150px] shadow-lg"
          onClick={() => {
            setState((prev) => ({ ...prev, address_modal: true }));
          }}
        >
          <div>
            <div className="text-base text-center font-medium">Хаяг холбох</div>
            <div className="text-[10px] text-center">
              Taobao Pinduoduo хаяг холбох заавар
            </div>
          </div>

          <MdOutlineLocationOn className="w-full h-full" />
        </div>
        <div
          className="flex items-center flex-col text-[#F55B00] p-2 bg-white rounded-md cursor-pointer w-[150px] h-[150px] shadow-lg"
          onClick={() => {
            setState((prev) => ({ ...prev, modal_price: true }));
          }}
        >
          <div>
            <div className="text-base text-center font-medium">Үнэ тариф</div>
            <div className="text-[10px] text-center">
              Таны ачааны үнэ хэрхэн бодогддог вэ?
            </div>
          </div>
          <MdPriceCheck className="w-full h-full" />
        </div>
        <div
          className="flex items-center flex-col text-[#F55B00] p-2 bg-white rounded-md cursor-pointer w-[150px] h-[150px] shadow-lg"
          onClick={() => {
            setState((prev) => ({ ...prev, missModal: true }));
          }}
        >
          <div>
            <div className="text-base text-center font-medium">
              Ачаа бүртгэл
            </div>
            <div className="text-[10px] text-center">
              Хугацаандаа ирээгүй ачааг админаар шалгуулах
            </div>
          </div>
          <CiBoxes className="w-full h-full" />
        </div>
        <div
          className="flex items-center flex-col text-[#F55B00] p-2 bg-white rounded-md cursor-pointer w-[150px] h-[150px] shadow-lg"
          onClick={() => {
            setState((prev) => ({ ...prev, linkModal: true }));
          }}
        >
          <div>
            <div className="text-base text-center font-medium">
              Линк захиалга
            </div>
            <div className="text-[10px] text-center">
              Линк захиалга хийлгэх заавар бичлэг
            </div>
          </div>
          <RxVideo className="w-full h-full" />
        </div>
        <div
          className="flex items-center flex-col text-[#F55B00] p-2 bg-white rounded-md cursor-pointer w-[150px] h-[150px] shadow-lg"
          onClick={() => {
            setState((prev) => ({ ...prev, for_modal: true }));
          }}
        >
          <div>
            <div className="text-base text-center font-medium">
              Хориотой бараа
            </div>
            <div className="text-[10px] text-center">
              Хилээр нэвтрүүлэхийг хориглох барааны жагсаалт
            </div>
          </div>
          <RxVideo className="w-full h-full" />
        </div>
        <div
          className="flex items-center flex-col text-[#F55B00] p-2 bg-white rounded-md cursor-pointer w-[150px] h-[150px] shadow-lg"
          onClick={() => {
            setState((prev) => ({ ...prev, modal_training: true }));
          }}
        >
          <div>
            <div className="text-base text-center font-medium">Сургалт</div>
            <div className="text-[10px] text-center">
              Өрөө захиалга хийж сурах сургалт
            </div>
          </div>
          <RxVideo className="w-full h-full" />
        </div>
        <div
          className="flex items-center flex-col text-[#F55B00] p-2 bg-white rounded-md cursor-pointer w-[150px] h-[150px] shadow-lg"
          onClick={() => {
            setState((prev) => ({ ...prev, modal_raiting: true }));
          }}
        >
          <div>
            <div className="text-base text-center font-medium">Үнэлгээ</div>
            <div className="text-[10px] text-center">
              Дэлгүүрийн үнэлгээ чансаа шалгах бичлэг
            </div>
          </div>
          <RxVideo className="w-full h-full" />
        </div>
      </div>
      <Training modal={state?.modal_training} setModal={setState} />
      <Address
        address_modal={state?.address_modal}
        setAddress_modal={setState}
      />
      <MissModal modal={state?.missModal} setModal={setState} />
      <LinkModal modal={state?.linkModal} setModal={setState} />
      <RaitingModal modal={state?.modal_raiting} setModal={setState} />
      <PriceModal modal={state?.modal_price} setModal={setState} />
      <ForbiddenModal modal={state?.for_modal} setModal={setState} />
    </React.Fragment>
  );
};

export default React.memo(HomeModals);
